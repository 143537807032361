export const unAuthenticatedRoutes = {
    LOGIN: "/login",
    SIGN_UP: "/signup",
    // RESET_PASSWORD: "/reset-password/:resetToken",
    REGISTER: "/register",
    RESET_PASSWORD: "/reset-password/",
    ENTEROTP: "/enter-otp/",
    FORGET_PASSWORD: "/forget-password",
    VERIFY_EMAIL: "/verify-email",
    EMAIL_VERIFICATION: "/email-verification/:token",
    PRIVACY_POLICY: "/privacy-policy",
    CONTACT_US: "/contact-us",
    TERMS_CONDITIONS: "/terms-conditions",
};

export const authenticatedRoutes = {
    DASHBOARD: "/dashboard",
    SETTING: "/setting",
    ARTIST_DETAILS: "/:eventId/artist-details/:id",
    ADD_EDIT_ARTIST: "/:eventId/artist/:id",
    VERIFY_TICKET: "/verify-ticket/:id",
    USERMANAGEMENT: "/user-management",
    USER_PURCHASED: "/user-management/user-purchases",
    USER_PROFILE: "/user-management/user-profile",
    EDIT_PROFILE: "/edit-profile",
    EVENTMANAGEMENT: "/event-management",
    SERVICEMANAGEMENT: "/service-management",
    SERVICE_DETAILS: "/service-management/service-details/:id",
    ADD_SERVICE: "/service-management/add-service",
    EDIT_SERVICE: "/service-management/edit-service",
    EVENT_DETAILS: "/event-management/event-details/:id",
    EVENT_DETAILS_QR_TICKET_MAPPER:
        "/event-management/event-details/:id/qr-ticket-mapper/:userId",
    EVENT_DETAILS_CREATE_PURCHASE:
        "/event-management/event-details/:eventId/:purchaseId",
    EVENT_DETAILS_CREATE_PURCHASE_OTP:
        "/event-management/event-details/:eventId/:purchaseId/otp/:userId",
    TICKET_MANAGEMENT: "/ticket-management",
    BOOKINGMANAGEMENT: "/booking-management",
    BOOKING_DETAILS: "/booking-management/booking-details/:id",
    ADD_EVENT: "/event-management/add-event",
    EDIT_EVENT: "/event-management/edit-event/:id",
    ADD_BOOKING: "/booking-management/add-booking",
    ADD_BOOKING_TABLE: "/booking-management/add-booking/add-booking-table",
    ADD_BUSINESS: "/business-management/add-business",
    EDIT_BOOKING: "/booking-management/edit-booking/:id",
    BUSINESS_MANAGEMENT: "/business-management",
    // EDIT_BOOKING_TABLE: "/edit-booking-table",
    BUSINESS_MANAGEMENT_DETAIL: "/business-management/business-detail/:id",
    TICKET_MANAGEMENT_DETAIL:"/ticket-management/ticket-detail",
    BUSINESS_MANAGEMENT_UPDATE: "/business-management/edit-business/:id",
    EDIT_BOOKING_TABLE: "/booking-management/edit-booking-table/:id",
    STRIPE_OAUTH: "/stripe/oauth/callback",
    PRIVACY_POLICY: "/privacy-policy",
    CONTACT_US: "/contact-us",
    TERMS_CONDITIONS: "/terms-conditions",

    // ************************************ Vendor routes ************************************
    SERVICE_ORDER_LISTING: "/service-management/service-order-listing/:id",
    BOOKING_ORDER_LISTING: "/booking-management/booking-order-listing/:id",
    VENDOR_EDIT_BOOKING: "/booking-management/edit-booking/:id",
    MY_EARNING: "/my-earning",
    VENDOR_SERVICE_DETAILS: "/service-management/service-details/:id",
    VENDOR_BOOKING_DETAILS: "/booking-management/booking-details/:id",
    VENDOR_EDIT_SERVICE: "/service-management/edit-service",
    PAYOUT: "/payout",
    CURRENCY_MANAGEMENT: "/currency-management",
    ADD_CURRENCY: "/currency-management/add-currency",
    VIEW_TRANSACTION: "/transactions/:payoutId/:vendorId",
};
