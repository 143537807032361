import _ from "lodash";
import * as lodashIsEmpty from "lodash.isempty";
import moment from "moment";
import { unAuthenticatedRoutes, authenticatedRoutes } from "../routes/constant";
import { MAX_NUMBER_INPUT_LIMIT } from "../constants/constant";

const utilService = {
    loginUrl: unAuthenticatedRoutes.LOGIN,
    defaultUrl: authenticatedRoutes.DASHBOARD,
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    apiUrlV2: process.env.REACT_APP_API_URL_V2,
    toNumber,
    // redirectToLogin,
    redirectTo,
    redirectToReturnUrl,
    getUrlParameterByName,
    isEmpty,
    getQueryParams,
    getUrlParam,
    replaceNullWithPlaceholder,
    getValue,
    getDate,
    getTime,
    createDynamicUrl,
    textLimit,
    formatNumberWithCommas,
    formatCurrency,
    numberMaxLimitValidator
};


// Format number with commas (e.g., 10000 -> 10,000)
function formatNumberWithCommas(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function formatCurrency(number) {
    return `$${formatNumberWithCommas(number)}`;
};

function numberMaxLimitValidator(message, number=MAX_NUMBER_INPUT_LIMIT) {
    return {
        transform: (value) => +`${value}`,
        type: "number",
        max: number,
        message: createDynamicUrl(message, {number: formatNumberWithCommas(number)})
    }
};


function getValue(...param) {
    return _.get(...param);
}

function replaceNullWithPlaceholder(value, placeHolder = "--") {
    if (!value) {
        return placeHolder;
    }
    return value;
}

// function redirectToLogin(loginUrl = utilService.loginUrl) {
//     let returnUrl = encodeURIComponent(
//         window.location.pathname.replace(/[//]+/, "") + window.location.search
//     );
//     // utilService.redirectTo(loginUrl + "?returnUrl=" + returnUrl);
// }

function redirectTo(url, state) {
    window.location.href = utilService.baseUrl + url;
}

function redirectToReturnUrl() {
    utilService.redirectTo(
        utilService.getUrlParameterByName("returnUrl")
            ? "/" + utilService.getUrlParameterByName("returnUrl")
            : utilService.defaultUrl
    );
}

function getUrlParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = `[\\?&]${name}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function textLimit(text = "", limit = 30) {
    if (text?.length > limit) {
        return `${text?.substring(0, limit)}...`;
    }
    return text;
}

function isEmpty(value) {
    return lodashIsEmpty(value);
}

function numberFormat(number, n, x, s, c) {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(
        new RegExp(re, "g"),
        `$&${s || ","}`
    );
}

function toNumber(input, decimal = 2) {
    if (input && !isNaN(input)) {
        return numberFormat(input, decimal, 3);
    }
    return input;
}

function getQueryParams(query, param) {
    let params = new URLSearchParams(query);
    return params.get(param);
}

function getUrlParam(urlParam, search) {
    return new URLSearchParams(urlParam).get(search);
}

function getDate(date) {
    return moment(date).format("DD MMM YYYY");
}

function getTime(time) {
    return moment(time).format("hh:mm A");
}

// replace object key with value on matching url sub string
function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
    }
    return dynamicUrl;
}

export const currencyDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export function handleBlobAsJson(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            try {
                const jsonData = JSON.parse(event.target.result);
                resolve(jsonData); // Resolve the promise with the parsed JSON object
            } catch (error) {
                reject(error); // Reject the promise if JSON parsing fails
                console.error("Error parsing JSON:", error);
                console.error("Raw content:", event.target.result);
            }
        };

        reader.onerror = function () {
            reject(new Error("Failed to read Blob")); // Reject the promise on reader error
        };

        reader.readAsText(blob, "utf-8"); // Specify the encoding if necessary
    });
}

export default utilService;
