import { STORAGE_CONST } from "../constants/constant";
import LocalStorageService from "./local-storage.service";

const AuthService = {
    isTokenExist,
    getToken,
    getUserInfo,
    getRole,
};

function getRole() {
    console.log("LOCAL___STORAGE", localStorage.getItem("TOKEN"));
    let userInfo ;
    try{
        userInfo = JSON.parse(localStorage.getItem("TOKEN") || "{}");
    }catch(e){
        console.log("Invalid Value---", e);
        
    }
        
   
    // let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        // userInfo = JSON.parse(userInfo);
        return userInfo.role == "ADMIN" ? true : false;
    }
    return null;
}

function isTokenExist() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        return true;
    }
    return false;
}

function getToken() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo.token;
    }
    return null;
}

function getUserInfo() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        return JSON.parse(userInfo);
    }
    return null;
}

export default AuthService;
